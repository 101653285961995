import { ARTWORK_PROCESSING_ERROR, ARTWORK_UPLOAD_DEFAULT } from '@/consts/websockets';

import { notify } from '@/functions/useNotification';

import { VuexActions } from '@/types/store';
import { WebsocketsState } from '@/types/websockets';

export const state: WebsocketsState = {
    artworkProcessingState: ARTWORK_UPLOAD_DEFAULT,
};

const getters = {
    artworkProcessingState(state: WebsocketsState) {
        return state.artworkProcessingState;
    },
};

const actions = {
    updateArtworkProcessingState({ commit }: VuexActions, { message }: { message: string }) {
        commit('setArtworkProcessingState', { message });
    },
    cancelArtworkUpload({ commit }: VuexActions) {
        notify(ARTWORK_PROCESSING_ERROR, 'ERROR');

        commit(
            'release/SET_SPINNER',
            {
                spinner: false,
                spinnerText: '',
            },
            { root: true },
        );
    },
};

const mutations = {
    setArtworkProcessingState(state: WebsocketsState, { message }: { message: string }) {
        state.artworkProcessingState = message;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
