import ReleaseService from '@/services/ReleaseService';

import {
    Release,
    ReleaseAdditionalArtist,
    ReleaseArtworkWizardTypes,
    ReleaseState,
    ReleaseTrack,
    Rightsholder,
} from '@/types/release';
import { VuexActions } from '@/types/store';

export const state: ReleaseState = {
    release: null,
    additionalArtists: [],
    signedArtworkUris: {},
    metadataIsEditing: false,
    metadataIsEditingPostSubmit: false,
    tracksIsEditing: false,
    tracksIsUploading: false, // used to block certain actions
    tracksIsProcessing: false,
    currentPlayerTrackUuid: null,
    dragLock: false,
    spinner: false,
    spinnerText: null,
    expandedTrackUuid: null,
    animationFinished: false,
    animationFrame: 0,
    errorStateTrack: null,
    artworkWizardTypes: null,
};

export const getters = {
    metadataIsEditing(state: ReleaseState) {
        return state.metadataIsEditing;
    },
    metadataIsEditingPostSubmit(state: ReleaseState) {
        return state.metadataIsEditingPostSubmit;
    },
    isSubmitted(state: ReleaseState) {
        return !!state.release?.submitted_at;
    },
    isNotSubmitted(state: ReleaseState) {
        return !state.release?.submitted_at;
    },
    readyToSubmit(state: ReleaseState) {
        return (
            !state.release?.submitted_at &&
            state.release?.metadata_status === 'Complete' &&
            state.release?.artwork_status === 'Complete' &&
            state.release?.tracks_status === 'Complete'
        );
    },
    canOrderProof(state: ReleaseState) {
        return (
            state.release?.operations.order_proof_enabled &&
            state.release?.submitted_at &&
            state.release?.retired_at === null
        );
    },
    animationFinished(state: ReleaseState) {
        return state.animationFinished;
    },
    tracksIsEditing(state: ReleaseState) {
        return state.tracksIsEditing;
    },
    tracksIsUploading(state: ReleaseState) {
        return state.tracksIsUploading;
    },
    isProcessingAudio(state: ReleaseState) {
        return state.tracksIsProcessing;
    },
    tracks: (state: ReleaseState) => (side: number) => {
        return state.release?.tracks[side];
    },
    playlist: (state: ReleaseState) => state.release?.tracks.flat(),
    allTracks(state: ReleaseState) {
        const tracks: { uuid: string; side: number; position: number }[] = [];

        state.release?.tracks.map((side, sideIndex) => {
            side.map((track, trackIndex) => {
                tracks.push({
                    uuid: track.uuid,
                    side: sideIndex + 1,
                    position: trackIndex + 1,
                });
            });
        });

        return tracks;
    },
    allReleaseTracks(state: ReleaseState) {
        const tracks: ReleaseTrack[] = [];

        state.release?.tracks.map((side) => tracks.push(...side));

        return tracks;
    },
    dispatchDate(state: ReleaseState) {
        return state.release?.dispatch_date;
    },
    releaseType(state: ReleaseState) {
        return state.release?.release_type;
    },
    formatTypeErrors(state: ReleaseState) {
        return state.release?.format_type_errors;
    },
    isAlbum(state: ReleaseState) {
        return state.release?.is_album_format;
    },
    isSingle(state: ReleaseState) {
        return state.release?.is_single_format;
    },
    status(state: ReleaseState) {
        return state.release?.status;
    },
    trackErrors(state: ReleaseState) {
        return state.release?.track_errors;
    },
    sideErrors(state: ReleaseState) {
        return state.release?.side_errors;
    },
    releaseGroupStatus(state: ReleaseState) {
        return state.release?.release_group_uuid ? 'Attached' : 'Not attached';
    },
    releaseGroupUuid(state: ReleaseState) {
        return state.release?.release_group_uuid ?? null;
    },
    totalTracks(state: ReleaseState) {
        return state.release?.tracks.flat().length;
    },
    maxTracks(state: ReleaseState) {
        return (
            (state.release?.release_type.product_type.sides || 0) *
            (state.release?.release_type.product_type.max_tracks_per_side || 0)
        );
    },
    findTrack: (state: ReleaseState) => (trackId: string) => {
        return state.release?.tracks.flat().find((track) => track.uuid === trackId);
    },
    currentTrack(state: ReleaseState) {
        return state.release?.tracks.flat().find((track) => track.uuid === state.currentPlayerTrackUuid);
    },
    isCD(state: ReleaseState) {
        return (
            state.release?.release_type &&
            state.release.release_type.product_type &&
            state.release.release_type.product_type.medium === 'CD'
        );
    },
    isVinyl(state: ReleaseState) {
        return (
            state.release?.release_type &&
            state.release.release_type.product_type &&
            state.release.release_type.product_type.medium === 'Vinyl'
        );
    },
    designOptions(state: ReleaseState) {
        return state.release?.release_type.product_type.design_options;
    },
    hasPrintedSleeve(state: ReleaseState) {
        return state.release?.selected_options.includes('printed_inner_sleeve');
    },
    hasArtwork: (state: ReleaseState) => (artworkNumber: number) => {
        return state.release?.artwork_slots[artworkNumber];
    },
    isMultiDisk(state: ReleaseState) {
        return state.release?.release_type.product_type.sides || 0 > 2;
    },
    isDeleted(state: ReleaseState) {
        return state.release?.status === 'Deleted';
    },
    isNotDeleted(getters: any) {
        return !getters.isDeleted;
    },
    currentSideNo(state: ReleaseState) {
        let sideNo: string | number = '-';

        state.release?.tracks.forEach((side, sideIndex) => {
            side.forEach((track) => {
                if (track.uuid === state.currentPlayerTrackUuid) sideNo = sideIndex + 1;
            });
        });

        return sideNo;
    },
    currentTrackNo(state: ReleaseState) {
        let trackNo: string | number = '-';

        state.release?.tracks.forEach((side) => {
            side.forEach((track, trackIndex) => {
                if (track.uuid === state.currentPlayerTrackUuid) trackNo = trackIndex + 1;
            });
        });

        return trackNo;
    },
    animationFrame(state: ReleaseState) {
        return state.animationFrame;
    },
    isSubmitReleaseEnabled(state: ReleaseState) {
        return !!state.release?.operations.submit_release_enabled && state.release?.retired_at === null;
    },
    isOrderProofEnabled(state: ReleaseState) {
        return !!state.release?.operations.order_proof_enabled;
    },
    get(state: ReleaseState) {
        return state.release ?? null;
    },
    errorStateTrack(state: ReleaseState) {
        return state.errorStateTrack ?? null;
    },
    ownership(state: ReleaseState) {
        return {
            c_year: state.release?.c_year,
            c_holder: state.release?.c_holder,
            p_year: state.release?.p_year,
            p_holder: state.release?.p_holder,
        };
    },
    artworkWizardTypes(state: ReleaseState) {
        return state.artworkWizardTypes;
    },
    hasProofs(state: ReleaseState) {
        return state.release?.has_proofs;
    },
    ogProductImage(state: ReleaseState) {
        return state.release?.og_product_image;
    },
};

export const actions = {
    async fetch({ commit, rootState }: VuexActions, releaseUuid: string) {
        const response: Release = await ReleaseService.show({
            userUuid: rootState.user.uuid,
            releaseUuid,
        });

        commit('SET_RELEASE', response);
    },
    async reload({ dispatch, state }: VuexActions) {
        await dispatch('fetch', state.release.uuid);
    },
    async update({ commit, rootState, state }: VuexActions, form: FormData) {
        const response = await ReleaseService.update({
            userUuid: rootState.user.uuid,
            releaseUuid: state.release.uuid,
            form,
        });

        commit('SET_RELEASE', response);
    },
    async attachReleaseGroup({ commit, rootState, state }: VuexActions, releaseGroupUuid: string) {
        const response = await ReleaseService.attachReleaseGroup({
            userUuid: rootState.user.uuid,
            releaseUuid: state.release.uuid,
            releaseGroupUuid,
        });
        commit('SET_RELEASE', response.data.data);
    },
    async animationFinished({ commit }: VuexActions) {
        commit('ANIMATION_FINISHED', true);
    },
    animationFrame({ commit }: VuexActions, animationFrame: number) {
        commit('ANIMATION_FRAME', animationFrame);
    },
    addTerritory({ commit }: VuexActions, territory: string) {
        commit('ADD_TERRITORY', territory);
    },
    async updateTrack(
        { dispatch, rootState, state }: VuexActions,
        { trackUuid, form }: { trackUuid: string; form: FormData },
    ) {
        await ReleaseService.updateTrack({
            userUuid: rootState.user.uuid,
            releaseUuid: state.release.uuid,
            trackUuid,
            form,
        });
        dispatch('fetch', state.release.uuid);
    },
    async deleteTrack({ commit, dispatch, rootState, state }: VuexActions, trackUuid: string) {
        await ReleaseService.destroyTrack({
            userUuid: rootState.user.uuid,
            releaseUuid: state.release.uuid,
            trackUuid,
        });
        dispatch('fetch', state.release.uuid);
        if (state.currentPlayerTrackUuid === trackUuid) commit('SET_CURRENT_TRACK', null);
    },
    async updateTrackPositions({ state, rootState, dispatch, getters }: VuexActions) {
        await ReleaseService.updateTrackPosition({
            userUuid: rootState.user.uuid,
            releaseUuid: state.release.uuid,
            tracks: getters.allTracks,
        });
        dispatch('fetch', state.release.uuid);
    },
    async approveArtwork({ commit, state, rootState }: VuexActions) {
        const response = await ReleaseService.approveArtwork({
            userUuid: rootState.user.uuid,
            releaseUuid: state.release.uuid,
        });
        commit('SET_RELEASE', response.data.data);
    },
    async resetArtwork({ dispatch, state, rootState }: VuexActions) {
        await ReleaseService.resetArtwork({
            userUuid: rootState.user.uuid,
            releaseUuid: state.release.uuid,
        });
        dispatch('fetch', state.release.uuid);
    },
    async updateArtworkOptions({ commit, state, rootState }: VuexActions, form: FormData) {
        const response = await ReleaseService.updateArtworkOptions({
            userUuid: rootState.user.uuid,
            releaseUuid: state.release.uuid,
            form,
        });
        commit('SET_RELEASE', response.data);
    },
    async submitRelease({ state, rootState, dispatch }: VuexActions) {
        await ReleaseService.submitRelease({
            userUuid: rootState.user.uuid,
            releaseUuid: state.release.uuid,
        });
        dispatch('fetch', state.release.uuid);
    },
    async orderProof({ state, rootState }: VuexActions) {
        await ReleaseService.orderProof({
            userUuid: rootState.user.uuid,
            releaseUuid: state.release.uuid,
        });
    },
    async cloneTrackRightsHolder({ state, rootState }: VuexActions, rightsHolder: Rightsholder) {
        await ReleaseService.cloneRightsHolder({
            userUuid: rootState.user.uuid,
            releaseUuid: state.release.uuid,
            rightsHolder,
        });
    },
    async fetchArtworkWizardTypes({ commit, rootState, state }: VuexActions) {
        const response = await ReleaseService.fetchArtworkWizardTypes({
            userUuid: rootState.user.uuid,
            releaseUuid: state.release?.uuid || '0123456789', // fallback for testing purposes
        });

        commit('SET_ARTWORK_WIZARD_TYPES', response);
    },
    regenerateArtwork({ state, rootState }: VuexActions) {
        return ReleaseService.regenerateArtwork({
            userUuid: rootState.user.uuid,
            releaseUuid: state.release.uuid,
        });
    },
    failTrackUpload({ state, rootState }: VuexActions, trackUploadId: number) {
        return ReleaseService.failTrackUpload({
            userUuid: rootState.user.uuid,
            releaseUuid: state.release.uuid,
            trackUploadId: trackUploadId,
        });
    },
    fetchCreatorCopiesPrice(
        { state, rootState }: VuexActions,
        { quantity, country_iso }: { quantity: number; country_iso: string },
    ) {
        return ReleaseService.fetchCreatorCopiesPrice({
            userUuid: rootState.user.uuid,
            releaseUuid: state.release.uuid,
            quantity: quantity,
            country_iso: country_iso,
        });
    },
};

export const mutations = {
    SET_SPINNER(state: ReleaseState, { spinner, spinnerText }: { spinner: boolean; spinnerText: string }) {
        state.spinner = spinner;
        state.spinnerText = spinnerText;
    },
    SET_RELEASE(state: ReleaseState, release: Release) {
        state.release = release;
        state.additionalArtists = release.additional_artists;
    },
    EDIT_METADATA(state: ReleaseState) {
        if (state.release?.submitted_at) {
            state.metadataIsEditingPostSubmit = true;
        } else {
            state.metadataIsEditing = true;
        }
    },
    CANCEL_METADATA(state: ReleaseState) {
        state.metadataIsEditing = false;
        state.metadataIsEditingPostSubmit = false;
    },
    EDIT_TRACKS(state: ReleaseState) {
        state.tracksIsEditing = true;
    },
    UPLOADING_TRACKS(state: ReleaseState) {
        state.tracksIsUploading = true;
    },
    TRACKS_UPLOADED(state: ReleaseState) {
        state.tracksIsUploading = false;
        state.tracksIsProcessing = false;
    },
    TRACKS_DONE(state: ReleaseState) {
        state.tracksIsEditing = false;
        state.tracksIsProcessing = false;
    },
    TRACKS_PROCESSING(state: ReleaseState, processing: boolean) {
        state.tracksIsProcessing = processing;
    },
    ANIMATION_FINISHED(state: ReleaseState, animationFinished: boolean) {
        state.animationFinished = animationFinished;
    },
    ANIMATION_FRAME(state: ReleaseState, animationFrame: number) {
        state.animationFrame = animationFrame;
    },
    SET_TRACK(state: ReleaseState, track: ReleaseTrack) {
        const side = state.release?.tracks[track.side - 1];
        const index = side?.findIndex((trackToReplace) => trackToReplace.uuid === track.uuid);

        side?.splice(index || 0, 1, track);
    },
    ADD_TRACK(state: ReleaseState, { side, track, index }: { side: number; track: ReleaseTrack; index?: number }) {
        if (state.release && !state.release?.tracks) {
            state.release.tracks = [];
        }

        if (state.release && !state.release?.tracks[side - 1]) {
            state.release.tracks[side - 1] = [];
        }

        const tracks = state.release?.tracks[side - 1];

        if (typeof index === 'number') {
            return tracks?.splice(index, 0, track);
        }

        tracks?.push(track);
    },
    REMOVE_TRACK(state: ReleaseState, { side, index }: { side: number; index: number }) {
        state.release?.tracks[side - 1].splice(index, 1);
    },
    SET_CURRENT_TRACK(state: ReleaseState, uuid: string) {
        state.currentPlayerTrackUuid = uuid;
    },
    SET_EXPANDED_TRACK(state: ReleaseState, uuid: string) {
        state.expandedTrackUuid = uuid;
    },
    SET_ERROR_STATE_TRACK(state: ReleaseState, uuid: string | null) {
        state.errorStateTrack = uuid;
    },
    ADD_ARTIST(state: ReleaseState, artist: ReleaseAdditionalArtist) {
        state.additionalArtists.push(artist);
    },
    ADD_ARTIST_TO_TRACK(
        state: ReleaseState,
        { trackId, artist }: { trackId: string; artist: ReleaseAdditionalArtist },
    ) {
        if (state.release && state.release.tracks) {
            const track = state.release.tracks.flat().find((track) => track.uuid === trackId);

            if (track) {
                track.additional_artists.push(artist);
            }
        }
    },
    REMOVE_ARTIST(state: ReleaseState, artist: number) {
        state.additionalArtists.splice(artist, 1);
    },
    REMOVE_ARTIST_FROM_TRACK(state: ReleaseState, { trackId, artistIndex }: { trackId: string; artistIndex: number }) {
        const artists = state.release?.tracks.flat().find((track) => track.uuid === trackId)?.additional_artists;

        if (artists && artistIndex >= 0 && artistIndex < artists.length) {
            artists.splice(artistIndex, 1);
        }
    },
    SET_ARTWORK_URIS(state: ReleaseState, uris: any[]) {
        state.signedArtworkUris = uris;
    },
    SET_IN_PROCESS_AT(state: ReleaseState, iso: string) {
        if (state.release) {
            state.release.in_process_at = iso;
        }
    },
    SET_ARTWORK_WIZARD_TYPES(state: ReleaseState, types: ReleaseArtworkWizardTypes) {
        if (state.release) {
            state.artworkWizardTypes = types;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
