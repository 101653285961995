import { RealtimeSalesApiResponse, SalesApiResponse } from '@/types/api/sales';
import { RealtimeSales, Sales } from '@/types/sales';

export const salesMapper: (response: SalesApiResponse) => Sales = (response) => {
    return {
        current_balance_formatted: response.current_balance_formatted,
        reporting_period: response.reporting_period,
        total_earnings_formatted: response.total_earnings_formatted,
        total_sales: response.total_sales,
        payout_enabled: response.payout_enabled,
        payout_threshold_met: response.payout_threshold_met,
        payout_threshold: response.payout_threshold,
        tipalti_enabled: response.tipalti_enabled,
    };
};

export const realtimeSalesMapper: (response: RealtimeSalesApiResponse) => RealtimeSales = (response) => {
    return {
        sales_last_week: response.sales_last_week,
        total_sales: response.total_sales,
    };
};
