<template>
    <div>
        <label for="locale" :class="{ '!text-[#717171] font-bold': props.giftStyling }">
            {{ t('Select your language') }}
        </label>

        <div class="mt-2 relative">
            <button
                id="locale"
                type="button"
                class="w-full border-transparent shadow focus:border-none bg-gray-750 focus:bg-gray-750 outline-none focus:outline-none ring-0 focus:ring-0 rounded transition truncate px-3 py-2"
                :class="{ '!bg-white border-gray-300': props.giftStyling }"
                @click="toggle"
            >
                <span class="flex items-center text-left">
                    <span class="flex-1 truncate" :class="{ '!text-[#313144]': props.giftStyling }">{{
                        props.selected.name
                    }}</span>
                    <font-awesome-icon icon="fa-solid fa-chevron-down" class="flex-none ml-3 text-gray-400" />
                </span>
            </button>

            <ul
                v-if="isOpen"
                class="absolute w-full bg-gray-750 shadow-modal max-h-56 rounded text-base overflow-auto z-20 top-[37px]"
                :class="{ '!bg-white border-gray-300': props.giftStyling }"
                tabindex="-1"
            >
                <li
                    v-for="locale in props.locales"
                    :key="locale.code"
                    class="cursor-pointer select-none relative py-2 pl-3 pr-9 transition bg-white bg-opacity-0 hover:bg-opacity-10"
                    :class="{ '!text-[#717171]': props.giftStyling }"
                    role="option"
                    @click.prevent="select(locale)"
                >
                    <div class="flex items-center" :class="isSelected(locale) ? 'text-orange-500' : 'text-white'">
                        <span class="flex-1 font-normal ml-3 block truncate">{{ locale.name }}</span>
                        <font-awesome-icon v-if="isSelected(locale)" icon="fa-solid fa-check" class="flex-none ml-3" />
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';

    import { trans as t } from 'laravel-vue-i18n';

    import { Locale } from '@/types/locales';

    const props = defineProps({
        locales: {
            type: [Object],
            required: true,
        },
        selected: {
            type: Object,
            required: true,
        },
        giftStyling: {
            type: Boolean,
            default: false,
        },
    });

    const emits = defineEmits(['changed']);

    const isOpen = ref(false);

    const isSelected = (locale: Locale) => {
        return props.selected.code === locale.code;
    };

    const select = (locale: Locale) => {
        emits('changed', locale.code);
        isOpen.value = false;
    };

    const toggle = () => {
        isOpen.value = !isOpen.value;
    };
</script>
