import { trans as t } from 'laravel-vue-i18n';

// todo: translating these strings should be done here but doing so forces the site locale to 'en' - investigate and fix

export const ARTWORK_UPLOAD_DEFAULT = 'Validating artwork…';
export const ARTWORK_VALIDATION_STARTING = 'Validating artwork…';
export const ARTWORK_VALIDATION_FINISHED = 'Original artwork validated…';
export const ARTWORK_VALIDATION_SUCCEEDED = 'Creating print ready artwork…';
export const ARTWORK_PROCESSING_STARTING = 'Creating release page preview…';
export const ARTWORK_PROCESSING_FINISHED = 'Print ready artwork created…';
export const ARTWORK_PROCESSING_ERROR = 'There was an error processing your artwork…';
export const ARTWORK_REGENERATION_STARTING = 'Regenerating artwork previews…';
export const ARTWORK_REGENERATION_FINISHED = 'Artwork regenerated…';
export const ARTWORK_AUTO_GENERATION_STARTED = 'Generating Artwork…';
