import { PagesApiResponse } from '@/types/api/pages';
import { PageItem } from '@/types/pages';

export const pagesMapper: (response: PagesApiResponse) => PageItem[] = (response) => {
    return response.map((page) => ({
        uuid: page.uuid,
        name: page.name,
        youtube_playlist_id: page.youtube_playlist_id,
        instagram_connected: page.instagram_connected,
        twitter_handle: page.twitter_handle,
        release_count: page.release_count,
        url: page.url,
        is_live: page.is_live,
        status: page.status,
        page_image: page.page_image,
        days_until_removed: page.days_until_removed,
    }));
};
