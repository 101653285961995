import CartService from '@/services/CartService';

import { Cart, CartItem } from '@/types/cart';
import { VuexActions } from '@/types/store';
import { CartActions, CartGetters, CartMutations, CartState } from '@/types/store/cart';

export const state: CartState = {
    cart: {
        items: [],
        totalQuantity: 0,
        totalIncVat: 0,
        totalIncVatFormatted: '',
        hasLimitedEdition: false,
        hasPreorders: false,
        shippingMinPrice: '',
        vatText: '',
        dispatchDate: '',
        shouldShowVATConfirmation: false,
    },
    display: false,
    loaded: false,
};

export const getters: CartGetters = {
    totalQuantity(state: CartState) {
        return state.cart.totalQuantity;
    },
    display(state: CartState) {
        return state.display;
    },
    loaded(state: CartState) {
        return state.loaded;
    },
    cart(state: CartState) {
        return state.cart;
    },
    items(state: CartState) {
        return state.cart.items;
    },
    totalIncVat(state: CartState) {
        return state.cart.totalIncVat;
    },
    totalIncVatFormatted(state: CartState) {
        return state.cart.totalIncVatFormatted;
    },
    shippingMinPrice(state: CartState) {
        return state.cart.shippingMinPrice;
    },
    vatText(state: CartState) {
        return state.cart.vatText;
    },
    hasLimitedEditionItem(state: CartState) {
        return state.cart.hasLimitedEdition ?? false;
    },
    containsPreorders(state: CartState) {
        return state.cart.hasPreorders;
    },
    dispatchDate(state: CartState): string {
        return state.cart.dispatchDate ?? '';
    },
    shouldShowVATConfirmation(state: CartState): boolean {
        return state.cart.shouldShowVATConfirmation;
    },
};

export const actions: CartActions = {
    async fetch({ commit }: VuexActions) {
        const response = await CartService.fetch();
        commit('SET_CART', response);
    },
    showCart({ commit }: VuexActions, status: boolean = true) {
        commit('SHOW_CART', status);
    },
    async addToCart({ dispatch }: VuexActions, item: CartItem) {
        await CartService.store({ item });
        await dispatch('fetch');
    },
    async remove({ dispatch }: VuexActions, item: { itemUuid: string; itemType: string }) {
        await CartService.remove(item);
        await dispatch('fetch');
    },
    async update({ dispatch }: VuexActions, { item, quantity }: { item: CartItem; quantity: number }) {
        await CartService.update({ item, quantity });
        await dispatch('fetch');
    },
};

export const mutations: CartMutations = {
    SET_CART(state: CartState, cart: Cart) {
        state.cart = cart;
        state.loaded = true;
    },
    SHOW_CART(state: CartState, status: boolean) {
        state.display = status;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
