import { AxiosResponse } from 'axios';

import { api } from '@/services/ApiService';

import { Country, Locale } from '@/types/locales';

export default {
    async fetchLocales() {
        const response: AxiosResponse<{ data: Locale[] }> = await api.get(`/api/locales`);

        return response.data.data;
    },
    async fetchShippingCountries({ force = false }: { force?: boolean }) {
        const response: AxiosResponse<{ data: Country[] }> = await api.get(`/api/shipping_countries`, {
            validateStatus: (status) => {
                return status >= 200 && status < 400;
            },
            headers: {
                'Cache-Control': force ? 'no-cache' : 'public',
            },
        });

        return { data: response.data.data, status: response.status };
    },
    async fetchBulkShippingCountries() {
        const response: AxiosResponse<{ data: Country[] }> = await api.get(`/api/bulk_shipping_countries`);

        return response.data.data;
    },
};
