import Cookies from 'js-cookie';
import { getActiveLanguage } from 'laravel-vue-i18n';

import { LocalesGetters, LocalesMutations, LocalesState } from '@/types/store/locales';

const state: LocalesState = {
    shippingCountry: Cookies.get('country') || 'GB',
    locale: getActiveLanguage() || 'en_GB',
    isModalOpen: false,
};

const mutations: LocalesMutations = {
    SET_LOCALE(state: LocalesState, locale: string) {
        state.locale = locale;
        Cookies.set('locale', locale, { expires: 365 });
    },
    SET_SHIPPING_COUNTRY(state: LocalesState, shippingCountry: string) {
        state.shippingCountry = shippingCountry;
        Cookies.set('country', shippingCountry, { expires: 365 });
    },
    OPEN_MODAL(state: LocalesState) {
        state.isModalOpen = true;
    },
    CLOSE_MODAL(state: LocalesState) {
        state.isModalOpen = false;
    },
};

const getters: LocalesGetters = {
    shippingCountry: (state: LocalesState) => state.shippingCountry,
    locale: (state: LocalesState) => state.locale,
    isModalOpen: (state: LocalesState) => state.isModalOpen,
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
};
