import GenresService from '@/services/GenresService';

import { Genre, GenreListItem, GenreReleaseGroups, GenresListResponse } from '@/types/genres';
import { GenresActions, GenresGetters, GenresMutations, GenresState } from '@/types/store/genres';

const state: GenresState = {
    items: [],
    releaseGroups: [],
    mainGenresList: [],
    subGenresList: [],
};

const getters: GenresGetters = {
    all(state: GenresState) {
        return state.items;
    },
    getMainGenresList(state: GenresState): GenreListItem[] {
        return state.mainGenresList;
    },
    getSubGenresList(state: GenresState): GenreListItem[] {
        return state.subGenresList;
    },
    getReleaseGroups(state: GenresState): GenreReleaseGroups[] {
        return state.releaseGroups;
    },
};

const actions: GenresActions = {
    async fetch({ commit }) {
        try {
            const response = await GenresService.fetch();
            commit('SET_GENRES', response.data);
        } catch (error) {
            console.error('Failed to fetch genres:', error);
        }
    },
    async fetchGenresList({ commit }) {
        try {
            const response = await GenresService.fetchGenresList();
            commit('SET_GENRES_LIST', response);
        } catch (error) {
            console.error('Failed to fetch genres list:', error);
        }
    },
    async fetchReleaseGroups({ commit }, { genre, pageUrl }: { genre: string; pageUrl?: string }) {
        try {
            const response = await GenresService.fetchReleaseGroups(genre, pageUrl);
            if (!pageUrl) {
                commit('SET_RELEASE_GROUPS', response.data);
            } else {
                commit('SET_APPEND_RELEASE_GROUPS', response.data);
            }
            return response;
        } catch (error) {
            console.error('Failed to fetch release groups:', error);
        }
    },
};

const mutations: GenresMutations = {
    SET_GENRES(state, genres: Genre[]) {
        state.items = genres;
    },
    SET_GENRES_LIST(state, genres: GenresListResponse) {
        state.mainGenresList = genres.main_genres;
        state.subGenresList = genres.sub_genres;
    },
    SET_RELEASE_GROUPS(state: GenresState, releaseGroups: GenreReleaseGroups[]) {
        state.releaseGroups = releaseGroups;
    },
    SET_APPEND_RELEASE_GROUPS(state: GenresState, newReleaseGroups: GenreReleaseGroups[]) {
        state.releaseGroups = [...state.releaseGroups, ...newReleaseGroups];
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
