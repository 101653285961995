import { TaxProfile, TaxProfileApiResponse } from '@/types/api/account';

export const taxProfileMapper = (response: TaxProfileApiResponse): TaxProfile => {
    return {
        tax_classification: response.tax_classification,
        is_uk_resident: response.is_uk_resident,
        name: response.name,
        country_iso: response.country_iso,

        utr_number: response.utr_number,
        ni_number: response.ni_number,
        vat_number: response.vat_number,

        uk_tax_resident: response.uk_tax_resident,
        uk_tax_liable: response.uk_tax_liable,
        uk_declare_royalties: response.uk_declare_royalties,
        confirm_profile_update: response.confirm_profile_update,
        confirm_information: response.confirm_information,
        position: response.position,
        signature: response.signature,

        address_line_1: response.address?.line1 ?? '',
        address_line_2: response.address?.line2 ?? '',
        city: response.address?.city ?? '',
        state: response.address?.state ?? '',
        postcode: response.address?.postcode ?? '',
        address_country_iso: response.address?.country_iso ?? '',
    };
};
